@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Quicksand:wght@400;500;700&display=swap');
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);


@import
  'main.scss',
  'hex-grid.scss',
  'button-style.scss',
  'bootstrap.scss',
  'loader.scss',
  'tracker.scss',
  'blog.scss';