#tracker-container
{
	font-size: 20px;
	margin:20px 40px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

::selection {
  background: #458e73;
}

input,
textarea,
[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

#monster-frame
{
/*   display:none; */
}

.grid
{
	width:100%;
	background-color:#dbdbc7;
	margin-top:20px;
	padding-bottom:8px;
}

.grid .grid-row
{
	display: grid;
	grid-template-columns: 54px 1fr 4fr 1fr 1fr 40px;
	position: relative;
	cursor:default;
	width:100%;
	height:40px;
	margin:2px 0;
}

.grid .grid-body
{
	display: grid;
	grid-template-columns: 1fr;
}

.grid .grid-th
{
	font-size: 24px;
}

.grid .grid-th,
.grid .grid-td
{
	position: relative;
	padding:5px 10px;
	background-color:#244e44;
	margin-right: 3px;
}

.grid .grid-body .grid-row.active-row .grid-td
{
	background-color:#458e73;
}

.grid .grid-body .grid-row.dead-row .grid-td
{
	background-color:#773636;
}

.grid .grid-body .grid-row.dead-row.active-row .grid-td
{
	background-color:#874545;
}

.grid .grid-td
{
	padding:0;
  height:50px;
}

.grid .grid-th:first-child
{
	margin:0;
}

.grid .grid-th:last-child,
.grid .grid-td:last-child
{
	margin-right: 0;
}

.grid .grid-td input
{
	border:none;
	outline:none;
	background-color:transparent;
	color:inherit;
	font-size:inherit;
	padding:10px;
	width:100%;
	height:100%;
}

.row-handle
{
	font-size: 32px;
	text-align: center;
	line-height: 47px;
	background-color: #244e44;
	cursor: move;
}

.row-delete
{
	font-size: 28px;
	text-align: center;
	line-height: 47px;
	background-color: #a83b3b;
	cursor: pointer;
}

.row-delete:hover
{
	background-color: #893636;
}

.dice
{
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 100%;
    background-image: url(/image/dice.svg);
    background-size: 100% auto;
    transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
}

.grid .grid-td:hover .dice
{
	opacity: 1;
}

.grid .grid-td:hover .dice:active
{
	opacity: 0.8;
}

.grid .grid-td .mod
{
  position:absolute;
  top:15px;
  left:40px;
  font-size:14px;
}

.footer
{
	display: grid;
	grid-template-columns: 1fr 1fr;
	width:100%;
	margin:30px 0;
}

.button
{
	display: inline-block;
}

.button .material-icons
{
	line-height: 60px;
}

.button-container
{
	width:100%;
}

.button-container.button-container-left
{
	text-align: left;
}

.button-container.button-container-right
{
	text-align: right;
}

#status-message
{
	display: inline-block;
	font-size: 14px;
	margin-left:20px;
	visibility: hidden;
	opacity: 0;
	transition:opacity 1s ease-in-out;
	-webkit-transition:opacity 1s ease-in-out;
}

#mod-container
{
	border-radius: 5px;
	background-color:#ffffff;
	width:100px;
	height:60px;
	position: absolute;
	top:100%;
	left:20px;
	margin-top:6px;
	z-index:1;
	color:#000000;
}

.arrow-up
{
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
}

#mod-container .arrow-up
{
	position: absolute;
	top:-6px;
	left:50%;
	margin-left:-6px;
}

#mod-container .mod-button
{
	color:#000;
	cursor:pointer;
	position:absolute;
	width:30px;
	height:30px;
	top:0;
	left:0;
	text-align:center;
	line-height:20px;
	font-size: 30px;
}

#mod-container input
{
	position: absolute;
	left:30px;
	width: 70px;
}

#mod-container #plus-mod-button
{
	top:0px;
	border-top-left-radius: 5px;
}

#mod-container #subtract-mod-button
{
	top:30px;
	border-bottom-left-radius: 5px;
}

#mod-container .mod-button:hover
{
	background-color:#e4e4e4;
}

#encounter-name
{
	display: inline-block;
	font-size: 20px;
	border: 1px solid transparent;
	padding:4px;
}

#encounter-name:hover
{
	border-color: #dbdbc7;
}

#open-encounter,
#new-encounter
{
	display: inline-block;
    top: 4px;
    left: 10px;
    position: relative;
    cursor: pointer;
}

#new-encounter
{
	display: inline-block;
	left:20px;
}

#round-counter
{
	display: inline-block;
	font-size: 28px;
}

#round-reset-button
{
	display: inline-block;
	top: 4px;
	left: 10px;
	position: relative;
	cursor: pointer;
	margin-right: 46px;
	line-height: 54px;
	vertical-align: top;
}

#round-reset-button .material-icons
{
	font-size: 28px;
	transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
}

#round-reset-button:hover .material-icons
{
	opacity: 0.8;
}

#round-reset-button:active .material-icons
{
	opacity: 0.5;
}

#round-reset-button.reset-click
{
	/*transform-origin: 24px 24px;*/
	-webkit-transform-origin: 14px 14px;
	animation: spin 1s forwards;
	-webkit-animation: spin 1s forwards;
}

@keyframes spin
{
	0%
	{
		transform: rotate(0deg);
	}

	100%
	{
		transform: rotate(360deg);
	}
}

@-webkit-keyframes spin
{
	0%
	{
		-webkit-transform: rotate(0deg);
	}

	100%
	{
		-webkit-transform: rotate(360deg);
	}
}

.monster-link
{
	position: absolute;
	width:45px;
	height:45px;
	top:0;
	right:5px;
	text-align: center;
    line-height: 40px;
    cursor: pointer;
    display: none;
}

.monster-link .material-icons
{
	display: inline-block;
    vertical-align: middle;
}

#encounter-list-cover
{
	display: none;
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.4);
	z-index:1000;
}

#encounter-list-container
{
	display: none;
	position: fixed;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	background-color:#073027;
	max-height: 500px;
	padding:20px;
	overflow-y:auto;
	z-index:1001;
	width:500px;
}

#encounter-list-container .encounter-row
{
	display: grid;
	grid-template-columns: auto 40px;
	grid-gap: 4px;
}

#encounter-list-container .encounter-row .encounter
{
	position: relative;
	padding:10px;
	background-color:#244e44;
	margin:2px 0;
	cursor:pointer;
	font-size: 16px;
	transition:background-color .3s ease-out;
	-webkit-transition:background-color .3s ease-out;
}

#encounter-list-container .encounter-row .encounter:hover
{
	background-color:#458e73;
}

#encounter-list-container .encounter-row .encoutner-delete-button
{
	background-color:#B44C4C;
	cursor: pointer;
	margin:2px 0;
	transition:background-color .3s ease-out;
	-webkit-transition:background-color .3s ease-out;
	position: relative;
}

#encounter-list-container .encounter-row .encoutner-delete-button:hover
{
	background-color: #963F3F;
}

#encounter-list-container .encounter-row .encoutner-delete-button .material-icons
{
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

#privacy-note
{
	color:#C0C5AA;
}

#privacy-note h4
{
	font-size: 14px;
}

#privacy-note p
{
	font-size: 12px;
}