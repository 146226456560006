* {
    box-sizing: border-box;
    background-repeat: no-repeat;
    font-weight: 400;
}

html {
    height:100%;
}

body {
    margin:0;
    font-family: 'Quicksand', serif;
    font-weight: 400;
    background-color: #073027;
    color:#f7f1e9;
    position: relative;
    min-height: 100vh;
}

a {
    color:#c0c5aa;
    text-decoration: none;
}

b {
    font-weight: 700;
}

h1, h2, h3, h4 {
    font-weight: bold;
}

.material-icons {
    font-size: 22px;
    vertical-align: middle;
}

#wrapper {
    min-height:100%;
    margin-bottom:-600px;
    padding-bottom: 600px;
}

@media (max-width: 1024px) {
    #wrapper {
        margin-bottom:-800px;
        padding-bottom: 800px;
    }
}

.text-box {
    color: #f7f1e9;
    padding: 40px;
    width: 100%;
    max-width: 1500px;
    margin: 2vw auto;
}

.text-box h1 {
    font-size:40px;
    margin:20px 0;
}

.text-box p {
    font-size:20px;
    line-height: 28px;
}

#intro-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#intro-container h1 {
    width: 100%;
}

#intro-container #intro-logo {
    width: 100%;
    max-width: 250px;
    align-self: center;
    text-align: center;
    margin-left: -28px;
}

#intro-container #intro-text-box {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

#intro-container #intro-text-box p {
    margin: 0;
}

#intro-container #intro-image {
    width:100%;
    max-width:720px;
    display: flex;
    align-self: center;
    justify-self:center;
}

@media (max-width: 768px) {
    #intro-container {
        grid-template-columns: 1fr;
    }
    
    #intro-container #intro-image {
        max-width:500px;
    }

    #intro-container #intro-logo {
        margin-left: 0;
        margin:0 auto;
    }
}

#cookie-consent {
    z-index: 101;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: #458e73;
    display: none;
    grid-gap: 20px;
    grid-template-columns: 1fr 100px;
}

#header-nav {
    position: relative;
    z-index:100;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    background-color: rgba(0,0,0,0.2);
}

#header-nav #dm-logo {
    display: block;
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 30px;
    width: 60px;
    height: 60px;
    background-image: url(/image/dmt.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    image-rendering: -webkit-optimize-contrast;
}

#header-nav #header-nav-link-container {
    position: absolute;
    left:150px;
    top:0px;
}

#header-nav #header-nav-link-container .nav-hex-link {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width:100px;
    margin-top:-30px;
    transition: margin-top 0.3s ease-out;
    -webkit-transition: margin-top 0.3s ease-out;
}

#header-nav #header-nav-link-container .nav-hex-link:hover {
    fill:#4FA183;
    margin-top:-10px;
}

#header-nav #header-nav-link-container .nav-hex-link polygon {
    fill:#458e73;
    transition:fill 0.3s ease-out;
    -webkit-transition:fill 0.3s ease-out;
}

#header-nav #header-nav-link-container .nav-hex-link:hover polygon {
    fill:#4FA183;
}

#header-nav #header-nav-link-container .nav-hex-link .nav-hex-link-text {
    text-align: center;
    color:#f7f1e9;
    position: absolute;
    font-size: 16px;
    top:54%;
    left:50%;
    transform: translate(-50%, -40%);
    -webkit-transform: translate(-50%, -40%);
    transition:transform 0.3s ease-out;
    -webkit-transition:-webkit-transform 0.3s ease-out;
    line-height: 18px;
}

#header-nav #header-nav-link-container .nav-hex-link .nav-hex-link-sub-text {
    color:#f7f1e9;
    opacity: 0.8;
    font-size: 12px;
    display: inline-block;
}

#header-nav #header-nav-link-container .nav-hex-link:hover .nav-hex-link-text {
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}


#auth-container {
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 1005;
}

#account-container {
    display: inline-block;
}

#account-section {
    position: absolute;
    top:20px;
	right: 20px;
	z-index:101;
}

#account-section .btn {
    margin:0 5px;
}

.account-tier-icon {
    position: absolute;
    top: -6px;
    left: -12px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    z-index: 1;
    outline: 1px solid transparent;
}

#account-tier-1 {     //bronze
    background-image: url('https://dm.tools/image/cache/catalog/tiers/bronze-140x140.png');
}

#account-tier-2 {     //silver
    background-image: url('https://dm.tools/image/cache/catalog/tiers/silver-140x140.png');
    animation: tierSpinner 6s ease-in-out infinite;
}

#account-tier-3 {     //gold
    background-image: url('https://dm.tools/image/cache/catalog/tiers/gold-140x140.png');
    animation: tierSpinner 6s ease-in-out infinite;    
}

@keyframes tierSpinner {
    0% {
        transform: rotateY(0deg);
    }

    90% {
        transform: rotateY(0deg);
    }

    95% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(-180deg);
    }
}

#account-picture-container {
    max-width: 200px;
}

@media(max-width: 1024px) {
    #account-section {
        top:10px;
    }

    #account-section .btn {
        display: block;
    }

    #account-section .dropdown {
        margin-top:10px;
    }
}

#account-section .dropdown-toggle img {
	width: 40px;
    height: 40px;
    image-rendering: -webkit-optimize-contrast;
    position: absolute;
    left: -38px;
    border-radius: 40px;
    top: -2px;
    border: 2px solid #458e73;
}

@media(max-width: 1024px) {
    #account-section .dropdown-toggle span.btn {
        display: none;
    }
}

.g-signin2 {
    opacity: 0;
    
    animation: signFade 0.3s 0.5s ease-in-out forwards;
    -webkit-animation: signFade 0.3s 0.5s ease-in-out forwards;
}

@keyframes signFade {
    100% { opacity: 1 }
}

@-webkit-keyframes signFade {
    100% { opacity: 1 }
}

.profile-container {
    display: none;
    font-family: 'Roboto', sans-serif;
    background-color:#fefefe;
    color:#000000;
    box-sizing: border-box;
    width:320px;
    position: absolute;
    top:40px;
    right:0;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
}

.profile-card {
    padding: 16px 24px;
    display: grid;
    grid-template-columns: 30% 70%;
}

.profile-picture {
    opacity: 0;
    width: 32px;
    height: 32px;
    float:right;
    cursor: pointer;

    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
}

.profile-picture,
.profile-container .card-profile-picture {
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

.card-profile-picture {
    width: 72px;
    height: 72px;
}

.profile-container .card-info {
    margin:14px 0 0 24px;
}

.profile-container .card-name {
    line-height:21px;
    font-size: 15px;
}

.profile-container .card-email {
    color:#737373;
    line-height:21px;
    font-size: 13px;
}

.profile-container .card-footer {
    background-color:#f5f5f5;
    border-top:1px solid #dfdfdf;
    padding: 8px 16px;
    text-align: right;
}

.profile-container .card-footer .card-sign-out-button {
    cursor: pointer;
    color:#737373;
    display: inline-block;
    font-size: 14px;
    padding: .7em .57em;
}

.profile-container .card-footer .card-sign-out-button:hover {
    background-color:#e6e6e6;
}

#currency-container {
    float:right;
    margin:10px;
}

#currency-container .dropdown-menu {
    margin-top:0px;
}

#currency-container .btn-link {
    color:#f7f1e9;
    text-align: left;
    text-decoration: none;
}

#currency-container .btn-link:hover {
    background-color:#f5f5f5;
    color:#262626;
}


/*--- menu button ---*/
.menu-button {
  position: absolute;
  display: none;
  width: 40px;
  height: 40px;
  top:19px;
  left:20px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  border: 4px solid #f7f1e9;
  z-index: 1;
}

.menu-button:hover {
  border-color: #458e73;
}

.menu-button:before, .menu-button:after {
  content: "";
  position: absolute;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  background-color: #f7f1e9;
  height: 4px;
  width: 30px;
  top: 50%;
  left: 50%;
  margin-left: -15px;
}

.menu-button:before {
  margin-top: -6px;
}

.menu-button:after {
  margin-top: 4px;
}

.menu-button.active {
    left:40px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-color: #8e4545 !important;
    background-color: #8e4545 !important;
}

.menu-button.active:before,
.menu-button.active:after {
  margin-left: -15px !important;
  margin-top: -2px !important;
}

.menu-button.active:after {
  transform: rotate(-90deg) !important;
  -webkit-transform: rotate(-90deg) !important;
}

#recent-posts-section {
    width:100%;
    padding:1em;
    background-color:#244e44;
}

#recent-posts-container {
    width:100%;
    padding:20px;
    margin:20px auto;
    max-width: 1200px;
    display: grid;
    grid-gap:30px;
    grid-template-columns: repeat(6, 1fr);
}

#recent-posts-container .post-link {
    width:100%;
    height:100%;
    background-color:#ffffff;
    position: relative;
    box-shadow: 0px 5px 30px -15px #000000;
    overflow: hidden;
}

//first 2
#recent-posts-container .post-link:nth-child(-n+2) {
    grid-column: span 3;
    padding-bottom: 30px;
}

//everything but first 2
#recent-posts-container .post-link:nth-child(n+3) {
    grid-column: span 2;
}

#recent-posts-container .post-link .post_thumbnail {
    width:100%;
    display:block;
    position: relative;
    height:250px;
}

#recent-posts-container .post-link .post_thumbnail img {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: 50% 50%;
}

#recent-posts-container .post-link .post_title {
    font-size: 24px;
    font-weight: bold;
    color:#000000;
    margin:20px 20px 22px;
    display: block;
}

#recent-posts-container .post-link .post_excerpt {
    margin:0px 20px 22px;
    display: block;
    color:#808080;
}

#recent-posts-container .post-link .post_thumbnail .post_time {
    position: absolute;
    bottom:0px;
    right:0px;
    padding:5px;
    background-color: #000000;
    color:#ffffff;
    box-sizing: border-box;
}

#recent-posts-container .post-link .post_more {
    color: #458e73;
    margin: 0px 20px 20px;
    display: block;
    font-weight: bold;
    position: absolute;
    bottom:0px;
}

#hero-button-container {
    margin: 40px auto;
    text-align: center;
}

#hero-button-container .hero-button {
    width: auto;
    margin: 5px;
    display: inline-block;
}

#discord-button {
    background-color: #5865f2;
    border:none;
    outline: none;
}

#discord-button:hover {
    background-color: #7983f5;
}

#discord-button img {
    fill: #ffffff;
    width: 26px;
    vertical-align: middle;
}

#all-posts-button {
    display: block;
    width:200px;
    margin:10px auto;
}

#gui-container {
    position: sticky;
    top: 0px;
    z-index: 101;
    float: right;
    display: none;
}

#gui-container .dg li.save-row {
    background:#000;
}

#gui-container .dg li.save-row .button {
    background-color: #828282;
    text-shadow: none;
    box-shadow: none;
}

#gui-container .dg li.save-row .button {
    height: auto;
}

#gui-container * {
    box-sizing: content-box;
}

/* ---- product section start ---- */


#product #tier-select {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

#product #tier-select ul {
    margin:20px 0;
    padding:0;
}

#product #tier-select ul li {
    list-style: none;
    height: 50px;
    line-height: 24px;
    vertical-align: middle;
    border-bottom: 1px solid #244e44;
    align-items: center;
    justify-content: center;
    display: grid;
}

#product #tier-select .tier-header {
    height:42%;
}

#product .tier-section {
    margin-top: -5px;
    text-align:center;
    padding:0 14px;
}

#product .tier-section .tier-header img {
    display: block;
    width:140px;
    margin:0px auto;
}

#product .tier-section .tier-header .tier-name {
    display: block;
    font-size: 20px;
    margin: 5px 0;
}

#product .tier-section .tier-header .tier-price {
    display: block;
    font-weight:bold;
    font-size: 24px;
}

#product .tier-section input {
    display: none;
}

#product .tier-section .btn {
    max-width: 180px;
    display: inline-block;
    font-weight:bold;
}

#product .tier-section .mobile-tier-item {
    display: none;
    margin-right:20px;
}

#product .tier-section .tier-strike {
    text-decoration: line-through;
    color:#B8B8B8;
}

#product .tier-section h3 {
    margin-top: 30px;
}

#product #tier-select .tier-section ul li.tier-coming-soon {
    line-height: 20px;
}

#product .tier-section span.tier-coming-soon {
    font-size: 12px;
    color: #a2a2a2;
    display: block;
    margin-top:-2px;
}

@media (max-width: 768px) {
    #product #tier-select {
        grid-template-columns: 1fr;
    }

    #product #tier-select .tier-header {
        height:auto;
    }

    #product .tier-section {
        margin-bottom: 40px;
    }

    #product .tier-section:first-child {
        display: none;
    }

    #product .tier-section .mobile-tier-item {
        display: inline;
    }
}

/* ---- product section end ---- */

#payment,
#payment-method-tabs {
    color: #000000;
}

.join-container {
    margin-bottom:50px;
}

.create-container {
    margin-top:50px;
}

#joinCodeInput {
    text-transform: uppercase;
    border:none;
    padding:10px 5px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    height:40px;
    margin-right: 10px;
}

#roomContainer {
    margin-bottom:20px;
}
    
#roomContainer .room-container {
    position: relative;
    display: inline-block;
    vertical-align:top;
}

#roomContainer .room {
    display: block;
    vertical-align:top;
    color:#073027;
    background-color:#f5e4d6;
    font-size:20px;
    margin:14px;
    width:140px;
    height:80px;
    line-height:80px;
    text-align:center;
    border-radius:5px;
    position: relative;
}

#roomContainer .room.room-dm {
    background-color:#CDDEC1;
    line-height: 60px;
}

#roomContainer .room.room-dm:after {
    content: "DM";
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%);
    font-weight: bold;
    font-size: 16px;
}
    
#roomContainer .room:first-child { margin-left:0px; }
#roomContainer .room:last-child { margin-right:0px; }
    
#roomContainer .room:hover {
    background-color:#d6cac0;
}

#roomContainer .room.room-dm:hover {
    background-color:#B1C0A6;
}

#roomContainer .room-remove {
    color: #ca4141;
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
    background: #fff;
    border-radius: 40px;
}

#roomContainer .room-remove:hover {
    color: #DA6A6A;
}

#roomContainer .room-remove .material-icons {
    font-size:26px;
}

#campaign-form {
    display: none;
    background-color: #073027;
    width:750px;
    position: fixed;
    top:50%;
    left:50%;
    padding:10px;
    border-radius: 5px;
    transform:translate(-50%, -50%);
    z-index:102;
}

#campaign-form * {
    display: block;
    margin: 10px 0;
}

#campaign-form-overlay {
    display: none;
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background-color:rgba(256,256,256,0.25);
    z-index:101;
}

#dice-tray-overlay {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out;
    position: fixed;
    z-index:98;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.4);
}

#dice-tray {
    position: fixed;
    z-index:100;
    left:0;
    bottom:0;
    width:100%;
    height:150px;
    background-color: rgba(68, 141, 114, 0.6);
    transform: translate(0, 100%);
    -webkit-transform: translate(0, 100%);
    transition: transform 0.3s ease-out;
    -webkit-transition: transform 0.3s ease-out;
}

#dice-tray .dice-tray-toggle {
    position: absolute;
    top:0;
    right:20px;
    z-index: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    padding:10px 15px;
    padding-right: 50px;
    font-size: 18px;
    background-color: rgba(68, 141, 114, 0.6);
    transform: translate(0%, -100%);
    -webkit-transform: translate(0%, -100%);
}

#dice-tray .dice-tray-toggle .material-icons {
    position: absolute;
    right: 10px;
    top: 10px;
}

#dice-tray.open-dice-tray {
    transform: translate(0, 0%);
    -webkit-transform: translate(0, 0%);
}

#dice-tray .dice-buttons {
    display: grid;
    overflow: hidden;
    align-items: center;
    height:100%;
    grid-template-columns: repeat(7, 1fr);
}

#dice-tray .dice-buttons .dice-button {
    display: flex;
    align-items: bottom;
    text-align: center;
    justify-content: center;
    justify-self:center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    background-position: 50% 50%;
    width:100%;
    height:100%;
    color: #fff;
    font-weight: normal;
    font-size: 20px;
    align-items: flex-end;
    margin-top: -40px;
    cursor:pointer;
    opacity: 0.8;
    transition: opacity 0.2s ease-out;
    -webkit-transition: opacity 0.2s ease-out;
}

#dice-tray .dice-buttons .dice-button:hover {
    opacity: 1;
}

#dice-tray .dice-buttons #dice-4.dice-button {
    background-image: url('/image/dice/d4.svg');
}

#dice-tray .dice-buttons #dice-6.dice-button {
    background-image: url('/image/dice/d6.svg');
}

#dice-tray .dice-buttons #dice-8.dice-button {
    background-image: url('/image/dice/d8.svg');
}

#dice-tray .dice-buttons #dice-10.dice-button,
#dice-tray .dice-buttons #dice-100.dice-button {
    background-image: url('/image/dice/d10.svg');
}

#dice-tray .dice-buttons #dice-12.dice-button {
    background-image: url('/image/dice/d12.svg');
}

#dice-tray .dice-buttons #dice-20.dice-button {
    background-image: url('/image/dice/d20.svg');
}

#dice-display {
    pointer-events: none;
    display: none;
    opacity: 0;
    transition:opacity 0.3s ease-out;
    -webkit-transition:opacity 0.3s ease-out;
    position: fixed;
    top:50%;
    left:50%;
    width:100%;
    z-index: 101;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    text-align: center;
}

#dice-display #dice-display-text {
    font-size: 32px;
    padding: 40px;
    text-align: center;
}

#dice-display .button {
    margin:0 10px;
    pointer-events: all;
}

#tray-background {
    background-image: url('/image/logo.svg');
    height: 65vh;
    width: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin: 0 auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.05;
}

#canvasContainer {
    overflow: hidden;
    position: fixed;
    top:0;
    z-index:99;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

#canvasContainer,
#canvasContainer canvas {
    pointer-events: none;
}

#dd-label {
    position: absolute;
    top:10px;
    left:10px;
}

/*--- dice tray end ---*/

#grid-img-link-container {
    display: grid;
    grid-template-columns: 1fr 1fr;    
    margin: 20px auto;
    max-width: 1200px;
}

#grid-img-link-container .grid-img-link {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin:20px;
}

#grid-img-link-container .grid-img-link img {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: 50% 50%;
}

#grid-img-link-container .grid-img-link .grid-img-link-overlay {
    z-index:1;
    position: absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;

    transition: opacity 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out;
    background: linear-gradient(45deg, rgba(16,28,5,0.8) 0%,rgba(255,255,255,0.2) 100%);
}

#grid-img-link-container .grid-img-link:hover .grid-img-link-overlay {
    opacity: 0.6;
}

#grid-img-link-container .grid-img-link .grid-img-link-text {
    z-index:2;
    position: absolute;
    left:0px;
    bottom:0px;
    width: 100%;
    padding:10px;
    box-sizing:border-box;
}

#grid-img-link-container .grid-img-link .grid-img-link-text h3,
#grid-img-link-container .grid-img-link .grid-img-link-text p {
    color:#ffffff;
    margin:0;
}

#grid-img-link-container .grid-img-link .grid-img-link-text h3 {
    font-size: 36px;
}

#grid-img-link-container .grid-img-link .grid-img-link-text p {
    margin:5px 0;
    font-size: 18px;
}

@media (max-width: 768px) {
    #grid-img-link-container {
        grid-template-columns: 1fr;
    }

    #grid-img-link-container .grid-img-link {
        height:200px;
        margin:20px 10px;
    }

    #grid-img-link-container .grid-img-link .grid-img-link-text h3 {
        font-size: 28px;
    }

    #grid-img-link-container .grid-img-link .grid-img-link-text p {
        font-size: 16px;
    }
}

#patreon-button {
    position: fixed;
    bottom:20px;
    right:20px;
    z-index:97;
}

#ad-sidebar {
    position: sticky;
    position: -webkit-sticky;
    top: 40px;
    right: 40px;
    min-width: 300px;
    max-width: 500px;
    float: right;
}

ins {
    background:unset !important;
}

#footer-ad-banner {
    margin:200px auto 80px;
    text-align: center;
    position: relative;
    max-width: 90%;
}

#footer-ad-banner .ad-remove {
    position: absolute;
    bottom:-32px;
    left:50%;
    transform: translate(-50%, 0);
    cursor: pointer;
    opacity: 0.8;
}

#footer-ad-banner .ad-remove:hover {
    opacity: 1;
    text-decoration: underline;
}

@media (max-width: 1024px) {
    #footer-ad-banner {
        display: none;
    }
}

#footer {
    margin-top: 16vw; 
    background-color: #112520;
    height: 320px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

#footer .footer-hex-container {
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
    pointer-events: none;
}

#footer .footer-hex-container .footer-hex-row {
    background: url('/image/hex.svg');
    background-repeat: repeat-x;
    background-size: 100px;
    height:100px;
    width:100%;
}

#footer .footer-hex-container .footer-hex-row:nth-child(odd) {
    transform: translate(0, 15px);
}

#footer .footer-hex-container .footer-hex-row:nth-child(even) {
    background-position: -50px 0;
}

#footer .footer-columns {
    display: grid;
    grid-gap: 20px;
    margin:50px 20px 20px;
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1024px) {
    #footer {
        height: 500px;
    }

    #footer .footer-columns {
        grid-template-columns: 1fr;
    }

    #footer .footer-columns .footer-column:first-child {
        display: none;
    }
}

#footer .footer-columns .footer-column a {
    display: block;
    margin: 5px 0;
}

#footer .footer-columns .footer-column {
    position: relative;
}

#footer .footer-columns .footer-column #footer-logo {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    image-rendering: -webkit-optimize-contrast;
}
    
#footer .footer-columns .footer-column h4 {
    font-size: 20px;
    margin:0;
    margin-bottom:10px;
}

#footer .footer-columns .footer-column .social-container a {
    display: inline-block;
    vertical-align: middle;
    margin:0 5px;
    opacity: 0.8;
    transition: opacity .3s ease-out;
}

#footer .footer-columns .footer-column .social-container a:hover {
    opacity: 1;
}

#footer .footer-columns .footer-column .social-container a:first-child {
    margin-left:0px;
}

#footer .footer-columns .footer-column .social-container a:last-child {
    margin-right:0px;
}

#footer .social-container img {
    width:34px;
    image-rendering: -webkit-optimize-contrast;
}

#footer .footer-text {
    font-size: 16px;
    width: calc(100% - 40px);
    text-align: center;
    margin: 40px 20px 0px;
}

@media (max-width: 1024px) {
    #footer .footer-text {
        font-size: 16px;
        width: calc(100% - 40px);
        text-align: left;
        margin: 40px 20px 0px;
    }
}

@media (max-width: 1024px) {
    #ad-sidebar {
        display: none;
    }

    #auth-container {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1005;
    }

    #auth-container .abcRioButton {
        width:100px !important;
    }

    .menu-button {
        display: block;
    }

    #header-nav #dm-logo {
        left: 50%;
        margin-left:-30px;
    }

    #header-nav-link-container {
        display: none;
    }

    #header-nav #header-nav-link-container {
        left: 0px;
        padding:10px;
        top: 80px;
        width: 100px;
        height: calc(100vh - 80px);
        background-color:inherit;
    }

    #header-nav #header-nav-link-container .nav-hex-link,
    #header-nav #header-nav-link-container .nav-hex-link:hover {
        margin-top:0px;
        width:80px;
    }

    #header-nav #header-nav-link-container .nav-hex-link .nav-hex-link-text {
        font-size:14px;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    .menu-button.active + #header-nav-link-container {
        display: block;
    }

    #header-nav #header-nav-link-container .nav-hex-link:nth-child(1) {
        animation-delay: 0s;
        -webkit-animation-delay: 0s;
    }

    #header-nav #header-nav-link-container .nav-hex-link:nth-child(2) {
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
    }

    #header-nav #header-nav-link-container .nav-hex-link:nth-child(3) {
        animation-delay: 0.2s;
        -webkit-animation-delay: 0.2s;
    }

    #header-nav #header-nav-link-container .nav-hex-link:nth-child(4) {
        animation-delay: 0.3s;
        -webkit-animation-delay: 0.3s;
    }

    #header-nav #header-nav-link-container .nav-hex-link:nth-child(5) {
        animation-delay: 0.4s;
        -webkit-animation-delay: 0.4s;
    }

    #header-nav #header-nav-link-container .nav-hex-link {
        transform:translate(-110%, 0);
        -webkit-transform:translate(-110%, 0);
        animation: mobLinkIn 0.2s ease-out forwards;
        -webkit-animation: mobLinkIn 0.2s ease-out forwards;
    }

    @keyframes mobLinkIn {
        100% {
            transform: translate(0, 0%);
        }
    }

    @-webkit-keyframes mobLinkIn {
        100% {
            -webkit-transform: translate(0, 0%);
        }
    }

    #recent-posts-container {
        grid-template-columns: 1fr;
    }

    #recent-posts-container .post-link {
        grid-column: span 1;
    }

    #dice-tray .dice-tray-toggle {
        font-size: 14px;
        left:auto;
        right:5px;
        transform: translate(0%, -100%);
        -webkit-transform: translate(0%, -100%);
    }

    #dice-tray .dice-buttons .dice-button {
        width:60%;
        background-size:100% auto;
    }

    #patreon-button {
        display: none;
    }

    #patreon-mob-link {
        display: inline;
    }

    #footer {
        margin-top: 28vw;
    }

    #footer .footer-hex {
        width: 120%;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
    }

    #footer .footer-hex svg:nth-child(1n+21) {
        display: none;
    }

    #footer .footer-hex svg:nth-child(1n+11) {
        transform: translate(-50%, -10px);
        -webkit-transform: translate(-50%, -10px);
    }

    #footer .footer-hex-container {
        transform: translate(-50%, -70%);
        -webkit-transform: translate(-50%, -70%);
    }
}